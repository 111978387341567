import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './pages/login';
import Register from './pages/register';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
const App = () => {
    return (
        <Router>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
        </Router>
    )
}

export default App;