import axios from 'axios';
import { WEB_API, AUTH_KEY } from '../env';
axios.defaults.baseURL = WEB_API;
axios.defaults.headers.common['x-api-key'] = AUTH_KEY;

axios.interceptors.response.use(
  (response) => {
    console.log('response', response);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
    }
    throw error;
  }
);
