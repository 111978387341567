import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Space, Modal, Row, Col } from 'antd';
import axios from 'axios';
import './index.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import ImageLogo from '../../assets/icons/logo.png';
import { getQueryParams } from '../../utils/url';
const queryString = require('query-string');

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 5,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: isMobile ? 6 : 10,
    span: 6,
  },
};
const Login = (props) => {
  const { t, i18n } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const userRef = useRef(null);
  const otpReceiveRef = useRef(null);
  const [params, setparams] = useState('');

  useEffect(() => {
    setTimeout(() => {
      userRef.current.focus();
    }, 100);
    return () => {};
  }, []);

  useEffect(() => {
    document.title = 'เข้าสู่ระบบ';
    let backurl = queryString.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).backurl;

    if (!backurl) {
      Modal.error({
        okText: t('close'),
        content: t('backurl_require'),
        onOk: () => {
          window.location.reload();
          // onReset();
        },
      });
    } else {
      let backurlParams = backurl.indexOf('?');
      if (backurlParams > -1) {
        backurlParams = backurl.substring(backurlParams, backurl.length);
        const stringquery = getQueryParams(backurlParams);
        setparams(stringquery);
      }
      setbackURLState(backurl);
    }
    getLanguage();
  }, []);
  const [backURLState, setbackURLState] = useState('');
  const [loading, setloading] = useState(false);
  const [loadingRegister, setloadingRegister] = useState(false);
  const [loadingOTP, setloadingOTP] = useState(false);
  const [tabProcess, setTabProcess] = useState(1);
  const [type, settype] = useState('login');
  const [user, setuser] = useState({});
  const formRef = useRef(null);
  const formRefOTP = useRef(null);
  const formRefOTPLogin = useRef(null);
  const [base64, setbase64] = useState('');

  const onFinish = async (values) => {
    setuser(values);
    loginApi(values);
  };

  const onFinishOTP = async (values) => {
    otpEnrollmentApi(values);
  };

  const onFinishOTPLogin = async (values) => {
    otpLoginApi(values);
  };

  const otpEnrollmentApi = async (values) => {
    setloadingOTP(true);
    try {
      let backurl = queryString.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).backurl;
      backurl = backurl.split('?')[0];
      values.backurl = backurl ?? '';
      values.username = user.username;
      values.password = user.password;
      const result = await axios({
        url: '/Otp/otpEnrollment',
        method: 'post',
        data: values,
      });
      if (result.status === 200) {
        setloadingOTP(false);
        const { data } = result;
        if (data.valid == false) {
          throw 500;
        } else {
          let paramUrl = data.backurl;
          if (params != '') {
            Object.keys(params).map((key) => {
              paramUrl += `&${key}=${params[key]}`;
            });
          }
          window.location.href = paramUrl;
        }
        // await localStorage.setItem('token', result.data);
      }
    } catch (error) {
      console.log(error);
      setloadingOTP(false);
      Modal.error({
        okText: t('close'),
        content: t('otpError'),
        onOk: () => {
          // onReset();
        },
      });
    }
  };

  const otpLoginApi = async (values) => {
    setloadingOTP(true);
    try {
      let backurl = queryString.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).backurl;
      backurl = backurl.split('?')[0];
      values.backurl = backurl ?? '';
      values.username = user.username;
      values.password = user.password;
      const result = await axios({
        url: '/Otp/otp',
        method: 'post',
        data: values,
      });
      if (result.status === 200) {
        setloadingOTP(false);
        const { data } = result;
        if (data.valid == false) {
          throw 500;
        } else {
          // window.location.href = data.backurl;
          let paramUrl = data.backurl;
          if (params != '') {
            Object.keys(params).map((key) => {
              paramUrl += `&${key}=${params[key]}`;
            });
          }
          window.location.href = paramUrl;
        }
        // await localStorage.setItem('token', result.data);
      }
    } catch (error) {
      console.log(error);
      setloadingOTP(false);
      Modal.error({
        okText: t('close'),
        content: t('otpError'),
        onOk: () => {
          // onReset();
        },
      });
    }
  };

  const loginApi = async (values) => {
    setloading(true);
    setTabProcess(1);
    try {
      let backurl = queryString.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).backurl;
      backurl = backurl.split('?')[0];
      values.backurl = backurl ?? '';
      values.username = values.username.replace('@scg.com', '');
      const result = await axios({
        url: '/Login/Login',
        method: 'post',
        data: values,
      });
      if (result.status === 200) {
        setloading(false);
        const { data } = result;

        if (data.userFound == true) {
          setTabProcess(3);
          otpReceiveRef.current.focus();
        } else {
          let paramUrl = data.backurl;
          if (params != '') {
            Object.keys(params).map((key) => {
              paramUrl += `&${key}=${params[key]}`;
            });
          }
          window.location.href = paramUrl;
        }
        // await localStorage.setItem('token', result.data);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
      Modal.error({
        okText: t('close'),
        content: t('signInError'),
        onOk: () => {
          // onReset();
        },
      });
    }
  };

  const registerApi = async (values) => {
    setloadingRegister(true);
    try {
      const backurl = queryString.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).backurl;
      values.backurl = backurl ?? '';
      const result = await axios({
        url: '/Enrollment',
        method: 'post',
        data: values,
      });
      if (result.status === 200) {
        setloadingRegister(false);
        const { data } = result;
        if (data.userFound == true) {
          Modal.error({
            okText: 'ปิด',
            content: data.message,
            onOk: () => {
              // onReset();
            },
          });
        } else {
          setTabProcess(2);
          setbase64(data.message);
        }

        // await localStorage.setItem('token', result.data);
      }
    } catch (error) {
      console.log(error);
      setloadingRegister(false);
      Modal.error({
        okText: t('close'),
        content: t('signInError'),
        onOk: () => {
          // onReset();
        },
      });
    }
  };

  const onLogin = async () => {
    settype('login');
    formRef.current.submit();
  };

  const onRegister = async () => {
    settype('register');
    formRef.current.submit();
  };

  const getLanguage = async () => {
    const language = await localStorage.getItem('language');
    if (language === null || language === '') {
      setDefaultLanguage('en');
    } else {
      i18n.changeLanguage(language);
      setDefaultLanguage(language);
    }
  };

  const changeLanguage = async (type) => {
    await localStorage.setItem('language', type);
    i18n.changeLanguage(type);
    setDefaultLanguage(type);
  };

  // const { Header, Footer } = Layout;
  return (
    <div className='antd-login-user-layout-container'>
      <Row style={{ height: '100%' }}>
        {!isMobile && (
          <div
            style={{
              padding: 10,
              backgroundColor: '#fff',
              position: 'absolute',
              display: 'flex',
              zIndex: 999,
              right: 20,
              top: 20,
            }}
          >
            <div
              className={`cursor-pointer language-text ${
                defaultLanguage === 'th' && 'active'
              }`}
              onClick={() => changeLanguage('th')}
            >
              ไทย
            </div>
            <div
              className={`cursor-pointer language-text ${
                defaultLanguage === 'en' && 'active'
              }`}
              style={{ marginLeft: 10 }}
              onClick={() => changeLanguage('en')}
            >
              EN
            </div>
          </div>
        )}
        <Col span={isMobile ? 0 : 6}>
          <div className='scg-background'>
            <div className='antd-pro-layouts-user-layout-header'>
              <div>
                <div alt='logo' />
                <img width={200} src={ImageLogo} />
              </div>
            </div>
          </div>
        </Col>
        <Col span={isMobile ? 24 : 18}>
          {isMobile && (
            <div
              style={{
                padding: 10,
                backgroundColor: '#fff',
                position: 'absolute',
                display: 'flex',
                zIndex: 999,
                right: 20,
                top: 20,
              }}
            >
              <div
                className={`cursor-pointer language-text ${
                  defaultLanguage === 'th' && 'active'
                }`}
                onClick={() => changeLanguage('th')}
              >
                ไทย
              </div>
              <div
                className={`cursor-pointer language-text ${
                  defaultLanguage === 'en' && 'active'
                }`}
                style={{ marginLeft: 10 }}
                onClick={() => changeLanguage('en')}
              >
                EN
              </div>
            </div>
          )}

          {tabProcess == 1 && (
            <div className='antd-pro-layouts-user-layout-content'>
              <div className='antd-pro-layouts-user-layout-top'>
                <Space direction='vertical' size='large'>
                  {isMobile && (
                    <div className='antd-pro-layouts-user-layout-header'>
                      <div>
                        <div alt='logo' />
                        <img
                          src={
                            'https://www.scgpackaging.com/public/frontend/assets/img/icon/logo__scg.svg'
                          }
                        />
                      </div>
                    </div>
                  )}
                  <span className='antd-pro-layouts-user-layout-title'>
                    {t('signIn')}
                  </span>
                </Space>
              </div>
              <div className='antd-pro-pages-user-login-style-main'>
                <Form
                  {...layout}
                  // {...formItemLayout}
                  // form={form}
                  key={'formRegister'}
                  ref={formRef}
                  name='register'
                  onFinish={onFinish}
                  initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                  }}
                  // scrollToFirstError
                >
                  {/* <Space direction="vertical" style={{ width: '100%' }} size="middle"> */}
                  <Form.Item
                    // initialValue={'paper_temp3'}
                    placeholder={t('username')}
                    name='username'
                    label={t('username')}
                    rules={[
                      {
                        required: true,
                        message: t('username_require'),
                      },
                    ]}
                  >
                    <Input
                      ref={userRef}
                      autoComplete='off'
                      onKeyDown={(e) =>
                        e.keyCode == 13 ? formRef.current.submit() : ''
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    // initialValue={'Scg@2020c'}
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: t('password_require'),
                      },
                    ]}
                    label={t('password')}
                  >
                    <Input
                      autoComplete='off'
                      type='password'
                      onKeyDown={(e) =>
                        e.keyCode == 13 ? formRef.current.submit() : ''
                      }
                    />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Space>
                      <Button
                        loading={loading}
                        onClick={onLogin}
                        style={{ minWidth: isMobile ? 130 : 130 }}
                        htmlType='button'
                        type='default'
                      >
                        {t('signIn')}
                      </Button>
                      <Link to={`/register?backurl=${backURLState}`}>
                        <span className='register-link'>{t(`enroll>>`)}</span>
                      </Link>
                    </Space>
                  </Form.Item>
                  {/* </Space> */}
                </Form>
              </div>
            </div>
          )}
          {tabProcess == 3 && (
            <div className='antd-pro-layouts-user-layout-content'>
              <div className='antd-pro-layouts-user-layout-top'>
                <Space direction='vertical' size='large'>
                  {isMobile && (
                    <div className='antd-pro-layouts-user-layout-header'>
                      <div>
                        <div alt='logo' />
                        <img
                          src={
                            'https://www.scgpackaging.com/public/frontend/assets/img/icon/logo__scg.svg'
                          }
                        />
                      </div>
                    </div>
                  )}
                  <span className='antd-pro-layouts-user-layout-title'>
                    {t('confirmOTP')}{' '}
                    <span style={{ fontSize: 14, fontWeight: 300 }}>
                      (Microsoft Authenticator)
                    </span>
                  </span>
                </Space>
              </div>
              <div
                className='antd-pro-pages-user-login-style-main'
                style={{ marginTop: 20 }}
              >
                <Form
                  {...layout}
                  // {...formItemLayout}
                  // form={form}
                  key={'formOTP'}
                  ref={formRefOTPLogin}
                  name='otpLogin'
                  onFinish={onFinishOTPLogin}
                  // scrollToFirstError
                >
                  {/* <Space direction="vertical" style={{ width: '100%' }} size="middle"> */}
                  <Form.Item
                    initialValue={''}
                    placeholder={t('otpReceive')}
                    name='token'
                    label={t('otpReceive')}
                    rules={[
                      {
                        required: true,
                        message: t('otp_require'),
                      },
                    ]}
                  >
                    <Input
                      ref={otpReceiveRef}
                      onKeyDown={(e) =>
                        e.keyCode == 13 ? formRefOTPLogin.current.submit() : ''
                      }
                      autoComplete='off'
                    />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button
                      loading={loadingOTP}
                      style={{ minWidth: 130 }}
                      htmlType='submit'
                      type='default'
                    >
                      {t('confirm')}
                    </Button>
                  </Form.Item>
                  {/* </Space> */}
                </Form>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Login;
